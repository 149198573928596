import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Container from '../components/global/container';
import CTA from '../components/global/cta';
import Header from '../components/global/header';
import Newsletter from '../components/global/newsletter';
import SEO from '../components/global/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import InsightCardList from '../components/insights/insight-card-list';
import PortableText from '../components/portableText';
import Layout from '../containers/layout';
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes
} from '../lib/helpers';

const postsPerPage = 10;
let arrayForHoldingPosts = [];

export const query = graphql`
  query InsightsPageQuery {
    static: sanityPage(slug: { current: { eq: "insights-page" } }) {
      _rawHeading
      _rawIntro
      seo {
        title
        keywords
        description
        image {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }
    posts: allSanityInsight(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publishedAt
          team {
            _key
            team {
              name
            }
          }
          cardMedia {
            image {
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
              }
            }
            video {
              asset {
                _id
                url
              }
            }
            alt
          }
          _rawExcerpt
        }
      }
    }
  }
`;

const InsightsPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  // const site = (data || {}).site;
  const content = (data || {}).static;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(10);

  const loopWithSlice = (start, end) => {
    const slicedPosts = postNodes.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  useEffect(() => {
    loopWithSlice(0, postsPerPage);
  }, []);

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };

  // if (!site) {
  //   throw new Error(
  //     'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //   );
  // }

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25
  });

  return (
    <>
      <Header headerStyle="clear" />
      <Layout>
      {content.seo && (
          <SEO
          title={content.seo.title !== null ? content.seo.title : false}
          description={content.seo.description !== null ? content.seo.description : false}
          keywords={content.seo.keywords !== null ? content.seo.keywords : false}
          image={content.seo.image !== null ? content.seo.image : false}
        />
        )}
        <Container>
          <section className={`section site-wide ${inView ? 'in-view' : ''}`} ref={ref}>
            <div className="hero hero--animation">
            </div>
          </section>

          {postNodes && <InsightCardList nodes={postsToShow} />}

          {arrayForHoldingPosts.length !== postNodes.length ? (
            <div className="section site-wide load-more">
              <button className="btn" onClick={handleShowMorePosts}>
                Load more insights
              </button>
            </div>
          ) : (
            <CTA />
          )}

          <Newsletter />
        </Container>
      </Layout>
    </>
  );
};

InsightsPage.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object
};

export default InsightsPage;
