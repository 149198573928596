import PropTypes from 'prop-types';
import React from 'react';
import InsightCard from './insight-card';

function InsightCardList(props) {
  return (
    <div className="site-wide">
      <div className="insight-cards grid-container grid-container--half">
        {props.nodes &&
          props.nodes.map((node, index) => <InsightCard {...node} isInList key={index} />)}
      </div>
    </div>
  );
}

InsightCardList.propTypes = {
  nodes: PropTypes.array
};

export default InsightCardList;
