import { format } from 'date-fns';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { buildImageObj, getInsightUrl } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';
import PortableText from '../portableText';

function InsightCard(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5
  });
  return (
    <div className={`grid-column insight-cards__item ${inView ? 'in-view' : ''}`} ref={ref}>
      <Link to={getInsightUrl(props.slug.current)}>
        <div>
          {props.cardMedia.image && props.cardMedia.image.asset && (
            <img
              src={imageUrlFor(buildImageObj(props.cardMedia.image))
                .url()}
              alt={props.cardMedia.alt}
            />
          )}
          {props.cardMedia.video && props.cardMedia.video.asset && (
            <video className="hero__media" autoPlay loop muted playsInline>
              <source src={props.cardMedia.video.asset.url} type="video/mp4" />
            </video>
          )}
        </div>
        <h2 className="h3-styling">{props.title}</h2>
        <div className="insight-cards__item-meta">
          {format(props.publishedAt, 'MMMM YYYY')}
          {props.team.map((member, index) => (
            <div key={index}>
              <span>|</span>
              <span>{member.team.name}</span>
            </div>
          ))}
        </div>
        {props._rawExcerpt && (
          <div className="insight-cards__item-content">
            <PortableText blocks={props._rawExcerpt} />
          </div>
        )}

        <button className="btn">Read more</button>
      </Link>
    </div>
  );
}

export default InsightCard;

InsightCard.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.object,
  cardMedia: PropTypes.object,
  team: PropTypes.array,
  _rawExcerpt: PropTypes.array,
  publishedAt: PropTypes.string
};
